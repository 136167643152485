import { Card } from "primereact/card";
import { OverlayPanel } from "primereact/overlaypanel";
import React,{ FC } from "react";
import { TawreedFormField, TawreedInputPlain } from "../../../../../../common";
import { DateUtils } from "../../../../../../helpers/date";
import { UserInfo } from "./user-info";

export interface OrderNoteProps{
    salesOrderNoteId: number;
    salesOrderId: number;
    createdAt: Date;
    note: string;
    userId: number;
    userName: string;
    mobileNo: string;
    email: string;
    partner: any
}
export const OrderNote:FC<OrderNoteProps> = (orderNote) =>{

    
    const customer = React.useRef<OverlayPanel>(null);
    return(
        <Card key={orderNote.salesOrderNoteId}>
            <OverlayPanel ref={ customer} style={{ width: '33vw' }} breakpoints={{ '960px': '60vw', '640px': '80vw' }}>
                <UserInfo userName={orderNote.userName} mobileNo={orderNote.mobileNo} email={orderNote.email}
                 partner={orderNote.partner.partnerName}></UserInfo>
                
            </OverlayPanel>
            <div className="formgrid grid">
            <div className="col-12 sm:col-6 md:col-12">
                <span className="text-primary cursor-pointer" onClick={(e) => customer.current?.toggle(e)} >{orderNote.userName}</span>
                <span> ({DateUtils.format(orderNote.createdAt,"datetime")})</span>
            </div>
            
            </div>
            
            <p className="m-0" style={{whiteSpace:'pre-wrap',overflowWrap: 'break-word'}}>
            {orderNote.note}
            </p>
        </Card>
    );
}