import React, { FC, useMemo, useRef, useState } from "react";
import { StoreProduct } from "../../../../catalog/store-products/data";
import { PartnerDto } from "../../../../partners/domain";
import { Dialog } from "primereact/dialog";
import { StoreProductsService } from "../../../../catalog/store-products/domain";
import { AuthUtils } from "../../../../auth/domain";
import { CssUtils } from "../../../../../helpers/css";
import { TawreedTable, TawreedTableProps } from "../../../../../common";
import { ObjectUtils } from "../../../../../helpers/object";
import { TawreedTableFormatters } from "../../../../../common/components/table/formatters";
import { Button } from "primereact/button";
import { PrimeIcons } from "primereact/api";
import { QuantityForm } from "./quantity-form";
import { tawreedTranslationService } from "../../../../../common/translations";

export interface CartFormProductsState {
    storeProduct?: StoreProduct;
}

export interface ProductsDialogProps {
    visible: boolean;
    // storeProduct: StoreProduct;
    // store: string,
    // accept: () => void;
    // changeQuantity: (quantity: number, bonusType: string) => void;
    className?: string;
    onSelect: (p: StoreProduct) => void;
    disabled: boolean;
    zoneId: number;
    actor: PartnerDto;
    store: string,
    priceListId?: any;
    changeQuantity: (quantity: number, bonusType: string) => void;
    hide: () => void;
    refs:any;
}

export const ProductsDialog: FC<ProductsDialogProps> = ({ visible, className, onSelect, disabled, zoneId, actor, changeQuantity, store,priceListId,hide,refs }) => {

    const service: StoreProductsService = useMemo(() => new StoreProductsService(), []);

    const [tabIndex, setTabIndex] = useState<number>(0);

    const [state, setState] = useState<CartFormProductsState>({
        storeProduct: undefined,
    });


    const tawreed: TawreedTableProps = {
        header: true,
        pagination: true,
        ds: {
            mode: 'lazy',
            asyncSearch: true,
            onSearch: (q) => service.searchSimilarWithPrice({ q: q.keyword ?? '', zoneId: zoneId, actor: actor, priceListId: priceListId }, q).then((res) => {
                console.log(res);
                return res;
            })
        },
        reload: false,
        dataKey: 'storeProductId',
        name: 'tbl.store.products',
        columns: [
            {
                field: AuthUtils.current().language === 'en' ? "titleEn" : "titleAr", 
                header: "lbl_store_products_product_id",
                sortable: false,
                sortField: AuthUtils.current().language === 'en' ? "titleEn" : "titleAr",
                filter: false,
                filterField: AuthUtils.current().language === 'en' ? "titleEn" : "titleAr",
                style: CssUtils.width('20%'),
            },
            {
                field: "store.partnerName",
                header: "lbl_store_products_store_name",
                sortable: false,
                sortField: 'storeName',
                filter: false,
                filterField: 'storeName',
                style: CssUtils.width('20%'),
            },
            {
                field: "expireDate",
                header: "lbl_store_product_expire_date",
                sortable: false,
                sortField: 'expireDate',
                filter: false,
                filterField: 'expireDate',
                body: (options: any) => {

                    return options.expireDate ? new Date(options.expireDate).toDateString().split(' ').splice(1, 3).join(' ') : ''
                },
                style: CssUtils.width('12%'),
            },
            {
                field: "priceListName",
                header: "lbl_price_scheme_price_list_name",
                sortable: false,
                filter: false,
                style: CssUtils.width('12%'),
            },
            {
                dataType: "numeric",
                field: "sellingPrice",
                header: "lbl_selling_price1",
                sortable: false,
                filter: false,
                body: (options: any) => {
                    let sellingPrice: number;
                    if (options.discountType.toLowerCase() === "percentage") {
                        sellingPrice = options.price - (options.price * options.discountValue / 100);
                    }
                    else if (options.discountType.toLowerCase() === 'bonus') {
                        sellingPrice = (options.price * (options.discountValue / (options.discountValue + 100)))
                    }
                    else {
                        sellingPrice = options.price - options.discountValue;
                    }
                    return <React.Fragment>{ObjectUtils.toLocalString(sellingPrice)}</React.Fragment>
                },
                style: CssUtils.width('12%'),
                className: CssUtils.className.field.amount,
            },
            {
                dataType: "numeric",
                field: "tax",
                header: "lbl_store_products_tax",
                sortable: false,
                filter: false,
                ...TawreedTableFormatters.percentage('tax'),
                style: CssUtils.width('12%'),
                className: CssUtils.className.field.amount,
            },
            {
                field: "actions",
                // header: "lbl_add_to_cart",
                body: (options: any) => {
                    return <Button
                        type="button"
                        style={{ width: "100%", height: "35px" }}
                        icon={PrimeIcons.SHOPPING_CART}
                        className="p-button-success"
                        onClick={() => {
                            setTabIndex(1);
                            onSelect(options);
                            setState(previous => { return { ...previous, isVisible: true, storeProduct: options }; });
                        }} />
                },
                style: CssUtils.width('12%'),
            }

        ],

        sort: {
            sortMode: 'single',
            initial: {
                sortField: 'productName',
                sortOrder: 1,
                multiSortMeta: undefined,
            },
        },

    }

    const ProductsDialogFooter = () => {
        return (<div>
            <Button label={tawreedTranslationService.translate('lbl_cancel')} icon="pi pi-times"
             onClick={()=>{setState({ storeProduct: undefined });
                        setTabIndex(0); hide();}} className="p-button-text p-button-secondary" />
            <Button onClick={() => {
                    if (refs.current && refs.current instanceof HTMLButtonElement) {
                        refs.current.click();
                        setState({ storeProduct: undefined});
                        setTabIndex(0);
                        hide();
                    } else {
                        console.warn('ref.current && ref.current instanceof HTMLButtonElement');
                    }
                }}className="p-button-text" style={tabIndex ===0 ? {display:'none'}:{}} label={tawreedTranslationService.translate('lbl_add_to_cart')} icon="pi pi-check" />
        </div>);
    }
    return (

        <Dialog style={{ maxWidth: '512px', minWidth: '55%' }}
         header={<div className="inline-flex align-items-center justify-content-center">
            {tabIndex ===1 && <Button icon={PrimeIcons.ARROW_LEFT} className="p-button-text mr-2" onClick={() => setTabIndex(0)} />}
            <span>{store}</span>
         </div>} visible={visible} footer={<ProductsDialogFooter />} onHide={()=>setState({ storeProduct: undefined })} draggable={false} position="top" closable={false} closeOnEscape={false}>
            <React.Fragment>
                {
                    tabIndex ===0 && 
                    <TawreedTable {...tawreed} />

            }
            {
                tabIndex ===1 && <QuantityForm
                visible ={tabIndex ===1 ? true : false}
                storeProduct={state.storeProduct!}
                store={store}
                changeQuantity={(quantity: number, bonusType: string) => {
                    changeQuantity(quantity, bonusType);
                }}
                back={()=>setTabIndex(0)}
                >

                </QuantityForm>
            }
            </React.Fragment>
        </Dialog>
    )
}
