import React, { useRef } from 'react'
import { DataView } from 'primereact/dataview';
import { Checkbox } from 'primereact/checkbox';

import { buildPaginatedRequest, Result } from '../../pagination';
import { tawreedTranslationService } from '../../translations';
import { computeTawreedActionTitle, TawreedAction, TawreedActionName, TawreedActionsContainer } from "../../actions";
import { DetailViewProps, DetailViewRefreshEvent } from './detail-view.props'
import { TawreedDetailViewItemTemplate } from './detail-view.item-template';
import { TawreedInputSearch } from '../form';

import './detail-view.scss';
import { AuthUtils } from '../../../modules/auth/domain';

export const TawreedDetailView: React.FC<DetailViewProps> = (props) => {

    //
    // states
    //
    // 1. component state
    const [loading, setLoading] = React.useState<boolean>(false);
    const [state, setState] = React.useState<'clean' | 'selected' | 'loaded' | 'flushed'>('clean');
    // 2. data view configuration
    const [result, setResult] = React.useState<Result<any> | undefined>(undefined);
    const [data, setData] = React.useState<any[]>();
    const [keyword, setKeyword] = React.useState<string>();
    // 3.
    const [active, setActive] = React.useState<any>(undefined); // key of item to be rendered
    const [selected, setSelected] = React.useState<any>({}); // list of selected items
    const [reScroll, setReScroll] = React.useState(false);


    /**
     *
     * @param e dataKey
     * @returns
     */
    const onActive = (e: any) => {
        setState('selected');
        setActive(e);
    }

    /**
     *
     * @returns
     */
    const onRefresh = (e: DetailViewRefreshEvent) => {
        if (e.name === 'refresh-all') {
            setState('flushed');
            setActive(undefined);
        }
        else if (e.name === 'refresh') {
            const dataKey = props.dataKey;
            const i = dataKey && e.item && e.item[dataKey] ? e.item[dataKey] : undefined;
            if (i) {
                if (result && result.content && result.content.length) {
                    const index = result.content.findIndex(e => e && e[dataKey] === i);
                    if (index !== -1) {
                        result.content[index] = e.item;
                        onActive(i);
                    }
                }
            }
        }
    };
    console.log(props);

    const onGlobalFilter = (e?: string) => {
        setKeyword(e);
    }

    function isCorrectSelection(e: any): boolean {
        if (e != null && e !== undefined) {
            if (Array.isArray(e)) {
                if (e.length) {
                    return true;
                }
            } else if (Object.keys(e).length) {
                return true;
            }
        }
        return false;
    }

    const selectOrUnSelectAll = React.useCallback((status: boolean) => {
        if (status) {
            setSelected({});
        } else {
            const ee: any = {};
            if (data && data.length) {
                data.forEach(i => ee[i[props.dataKey]] = true);
            }
            setSelected(ee);
        }

    }, [data, props.dataKey]);

    React.useEffect(() => {
        let mounted = true;

        if (mounted) {
            // prevent redundant loading
            if (state === 'clean' || (state === 'flushed' && active === undefined)) {
                //
                if (!loading) {
                    setLoading(true);

                    if (props.ds.mode === 'lazy') {
                        props.ds.onSearch(buildPaginatedRequest({ size: 0, sort: props.sort, filters: props.filter ? props.filter.initial : undefined }))
                            .then(data => {
                                if (!data || !data.data || !data.data.totalElements) {
                                    if (state === 'flushed') {
                                    }
                                    props.ds.onEmpty();
                                    setState('loaded');
                                    setLoading(false);
                                } else {
                                    setState('loaded');
                                    setLoading(false);
                                    setResult(data.data);
                                    //
                                    const i = data.data.content[0][props.dataKey];
                                    onActive(i);
                                }

                            })
                            .catch(() => {
                                setLoading(false);
                            });
                    }
                }
            }
        }
        return () => { mounted = false; }

    }, [active, state, loading, props]);

    React.useEffect(() => {
        let mounted = true;

        if (mounted) {
            if (result && result.content && result.content.length) {
                if (!keyword) {
                    setData(result.content);
                } else {
                    setData(result.content.filter(e => !!`${e[props.dataKey]}`.match(keyword)));
                }
            } else {
                setData([]);
            }
        }
        return () => { mounted = false; }

    }, [keyword, result, setData, props.dataKey]);
    console.log(data);

    React.useEffect(() => {
        let mounted = true;

        if (mounted) {
            if (props.selection && props.selection.selectAllOnLoad) {
                selectOrUnSelectAll(false);
            }
        }
        return () => { mounted = false; }

    }, [selectOrUnSelectAll, props.selection]);

    const handleKeydown = (event: any) => {
        if (event.key === (AuthUtils.current().language === "en" ? 'ArrowRight' : 'ArrowLeft')) {
            const idx = data?.findIndex(el => el.orderId === active) ?? -1;
            if (idx + 1 < (data?.length ?? 0))
                setActive(data?.[idx + 1].orderId ?? active);
        }
        if (event.key === (AuthUtils.current().language === "en" ? 'ArrowLeft' : 'ArrowRight')) {
            const idx = data?.findIndex(el => el.orderId === active) ?? -1;
            if (idx > 0)
                setActive(data?.[idx - 1].orderId ?? active);
        }

    };

    React.useEffect(() => {

        const el = document.getElementById("ref-dev");
        const el1 = el?.getElementsByClassName('p-grid')?.[0] ?? undefined;
        console.log(active);
        el1?.setAttribute('tabindex', '0');  //

        el1?.addEventListener("keyup", handleKeydown)
        setReScroll(!reScroll);


        return () => el1?.removeEventListener("keyup", handleKeydown)


    }, [active])
    React.useEffect(() => {
        const scrollToActiveElement = () => {
            const el = document.getElementById("ref-dev");
            const el1 = el?.getElementsByClassName('p-grid')?.[0] ?? undefined;
            const el2 = el1?.getElementsByClassName('p-highlight')?.[0] ?? undefined;
            if (el2) {
                el2.scrollIntoView({ inline: 'start', behavior: 'smooth', block: 'nearest' });
            }
        };

        setTimeout(scrollToActiveElement, 50);
    }, [active, reScroll, loading]); // Trigger scroll when active changes

    // GlobalSearchField
    const GlobalSearchField: React.FC = () => {
        return (
            <TawreedInputSearch
                className="flex-grow-1"
                name="tawreedTableGlobalSearch"
                type="search"
                placeholder={tawreedTranslationService.translate("lbl_search")}
                value={keyword}
                onEnterPressed={(e) => onGlobalFilter(e)}
                autoComplete="off" />
        )
    }

    const TawreedDetailViewHeader: React.FC = () => {
        const allSelected = selected && data && Object.keys(selected).length === data.length;
        return (
            <div className="grid">
                <div className="col-12">
                    <div className="col-3 col-offset-9 flex flex-wrap align-items-center justify-content-between">
                        {/* {
                            <Checkbox checked={allSelected} onChange={e => { selectOrUnSelectAll(!e.checked) }} className="mr-1" />
                        } */}
                        <GlobalSearchField />
                        <span className="ml-auto"></span>
                    </div>
                </div>
            </div>
        );
    }

    const TawreedDetailViewToolbar: React.FC = () => {

        if (!props.toolbar || !props.toolbar.actions || !props.toolbar.actions.length) {
            return <React.Fragment />;
        }

        const actions = props.toolbar.actions;
        const toSend: any[] = [];
        if (selected && result && result.content && result.content.length) {
            Object.keys(selected).forEach(e => {
                const i = result.content.find(i => e === '' + i[props.dataKey]);
                if (i) {
                    toSend.push(i);
                }
            });
        }

        const onExecute = (e: TawreedAction, b: boolean) => {
            if (e.name === TawreedActionName.ToggleSelection) {
                selectOrUnSelectAll(toSend.length > 0);
            } else {
                e.execute(toSend).then(() => {
                    if (b) {
                        const trigger = props.ds.trigger;
                        if (trigger === 'normal_and_action') {
                            setState('clean');
                            setActive(undefined);
                        }
                    }
                });
            }
        }

        const onTitle = (e: TawreedAction, b: boolean) => computeTawreedActionTitle(toSend, e.title);

        return (
            <div className="col-12">
                <TawreedActionsContainer className="tawreed-dataview-actions-container" onTitle={onTitle} onClick={onExecute} actions={actions} disabled={loading} stateFullDisabled={!isCorrectSelection(selected)} />
            </div>
        );
    }

    /**
     * item template
     */
    const itemTemplate = (item: any) => {
        const id = item[props.dataKey];
        const onChange = (checked: boolean) => {
            let next = selected;
            if (checked) {
                next[id] = true;
            } else {
                delete next[id];
            }
            setSelected({ ...next });
        }

        return (


            <TawreedDetailViewItemTemplate checked={selected[id]} onChange={onChange} activated={active === id} onClick={() => onActive(id)}>
                {
                    props.itemTemplate(item)
                }
            </TawreedDetailViewItemTemplate>


        )
    }

    return (
        <div className="grid w-full tawreed-detail-view">
            {
                props.title &&
                <div className="col-12">
                    <h4 style={{fontSize:'1.7rem'}}>{tawreedTranslationService.translate(props.title)}</h4>
                </div>
            }
            <TawreedDetailViewToolbar />
            <div className="col-12" id="ref-dev"  >
                <DataView
                    dataKey={props.dataKey}
                    value={data ? data : []}
                    header={<TawreedDetailViewHeader />}
                    // pagination

                    // sort
                    sortField={props.sort ? props.sort.sortField : undefined}
                    sortOrder={props.sort ? props.sort.sortOrder : undefined}
                    //
                    itemTemplate={itemTemplate}

                />
            </div>
            <div className="col-12">
                {active && props.detailTemplate(active, onRefresh)}
            </div>
        </div>
    )
}
