import React from 'react';
import { PrimeIcons } from 'primereact/api';
import { Panel } from 'primereact/panel';
import { useParams } from 'react-router-dom';
import { TawreedActionName } from '../../../../../common/actions';
import { TawreedFormSubmitAction, TawreedForm, TawreedFormField, TawreedFormMode, TawreedFormSubmitCallback, TawreedInputSwitch, TawreedInputText, TawreedFormSubmitProps, TawreedInputNumber, TawreedInputCustom, TawreedInputFile, TawreedInputPlain } from '../../../../../common/components/form';
import { tawreedTranslationService } from '../../../../../common/translations';
import { ProductDto, ProductsService } from '../../domain';
import { GlobalContext } from '../../../../../context';
import { CategoriesDropdown } from './categories-dropdown';

export const ProductView: React.FC = () => {
    const { id } = useParams<{ id: string}>();
    // di
    const { constants: { constants } } = React.useContext(GlobalContext);
    const service: ProductsService = React.useMemo(() => new ProductsService(), []);

    const [loading, setLoading] = React.useState<boolean>(false);
    const [data, setData] = React.useState<ProductDto>({});
       React.useEffect(() => {
        let mounted = true;
        if (id) {
            if (mounted) {
                setLoading(true);
            }
            service.getDetails(Number.parseInt(id))
                .then(res => {

                    if (mounted) {
                        setLoading(false);
                        setData(res);
                    }
                })
                .catch(() => {
                    if (mounted) {
                        setLoading(false);
                    }
                })
        } 
        return () => {
            mounted = false;
        };
    }, [id, service]);

    const onCreateOrSave = (data: ProductDto, cb: TawreedFormSubmitCallback<ProductDto>): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            resolve();
       })
    }

    const onSubmit: TawreedFormSubmitProps<ProductDto> = {
        showButton: false,
        resetOnSubmit: false,
        onSubmit: new TawreedFormSubmitAction<ProductDto>(TawreedActionName.ProductsSubmit, 'lbl_save', PrimeIcons.SAVE, onCreateOrSave),
    };
    console.log("constants?.productCategories.filter(x=>x.value === data.categoryId)[0].value",constants?.productCategories.filter(x=>x.value === data.categoryId));

    return (
        <React.Fragment>
            <TawreedForm title="lbl_product"
                dataKey="productId"
                data={data}
                submit={onSubmit}
                loading={loading} mode={"Edit"}
                useBack={undefined} useReset actions={[]} className="grid form-grid">

                <Panel header={tawreedTranslationService.translate('lbl_general_information')} className="col-12">
                    <div className="grid">

                        <TawreedFormField className="field col-12 md:col-6" name="categoryId" title="lbl_product_category">
                        {
                                <React.Fragment>
                                <br/>
                                <span>{ constants?.productCategories.filter(x=>x.value===data.categoryId)[0] ?
                                constants?.productCategories.filter(x=>x.value===data.categoryId)[0].label : ''}</span>
                                </React.Fragment>
                                
                            }
                        </TawreedFormField>
                        
                        <TawreedFormField className="field col-12 md:col-6" name="displayName" title="lbl_displayName">
                            <TawreedInputPlain name="displayName" render="form" />
                        </TawreedFormField>

                        <TawreedFormField className="field col-12 md:col-6" name="internationalCode" title="lbl_product_international_code">
                            <TawreedInputPlain name="internationalCode" render="form" />
                        </TawreedFormField>

                        <TawreedFormField className="field col-12 md:col-6" name="productTax" title="lbl_product_product_tax">
                            <TawreedInputPlain mode="numeric" name="productTax" render="form" />
                        </TawreedFormField>
                        <TawreedInputCustom render="form" name="categoryId" renderer={(c: any) => {
                            const fields = constants?.productCategoriesFields.find(e => e.categoryId === c)?.fields.filter(x => x.fieldName === "INGREDIENT");
                            return (<React.Fragment>

                                {
                                    fields &&
                                    data.fields &&
                                    fields.map((field, index) =>
                                        <React.Fragment key={index}>
                                            <TawreedFormField className="field col-12 md:col-6" name={data.fields!.filter(x=>x.fieldId===field.fieldId).at(0)?.value} title={field.fieldName}>

                                                <TawreedInputPlain render='form' value={data.fields!.filter(x=>x.fieldId===field.fieldId).at(0)?.value} />
                                            </TawreedFormField>
                                        </React.Fragment>)
                                }
                            </React.Fragment>)

                        }} />

                        <div className="col-12">
                            <div className="grid">
                                <TawreedFormField name="image" className="field col-12 md:col-3" title="lbl_product_images">
                                    <TawreedInputFile render="form" name="image" disabled={true} />
                                </TawreedFormField>
                            </div>
                        </div>

                    </div>
                </Panel>
            </TawreedForm>
        </React.Fragment>
    );
}
