import { Dialog } from "primereact/dialog"
import { tawreedTranslationService } from "../../../../../../common/translations";
import React, { FC, useState } from "react";
import { TawreedFormField, TawreedInputFile } from "../../../../../../common";
import { TawreedFile } from "../../../../../upload";
import { Button } from "primereact/button";
import OtpInput from "react-otp-input";

export interface OrderDeliverDocumentUploadDialogProps {
    visible: boolean;

    hide: () => void;
    requireOtp: boolean;
    payThroughUs: boolean;
    accept: (data: { file: string|undefined, code:string | undefined }) => void;
}

export const OrderDeliverDocumentUploadDialog:FC<OrderDeliverDocumentUploadDialogProps> = ({ visible, hide, requireOtp, payThroughUs, accept }) =>{

    const [dialogState, setDialogState] = useState<{
        file: File | undefined,
        code: string | undefined
    }>({
        file: undefined,
        code: undefined
    });

    const returnFile =() =>{
        if(dialogState.file){

            let document = "";
            let reader = new FileReader();
            reader.readAsDataURL(dialogState.file);
            reader.onload = function () {
                document = reader.result as string;
                let documentParts = document.split(',');
                if(documentParts.length>1){
                    document = documentParts[1];
                }
                else{
                    document = documentParts[0];
                }
                accept({file: document, code: dialogState.code});
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };
        }
        else{
            accept({file: undefined, code: dialogState.code});
        }
    }

    const OrderDocumentUploadDialogFooter = () => {
        return (<div>
            <Button label={tawreedTranslationService.translate('lbl_cancel')} icon="pi pi-times" onClick={()=> {setDialogState({file:undefined, code: undefined}); hide()}} className="p-button-text" /*disabled={dialogState.loading}*/ />
            <Button onClick={() => { setDialogState({file:undefined, code: undefined}); returnFile();}} label={tawreedTranslationService.translate('lbl_accept')} icon="pi pi-check"
                 disabled={requireOtp ? (!dialogState || !dialogState.code || !dialogState.file) : (payThroughUs ? (!dialogState.file): false) }
            />
        </div>);
    }
    return (
        <Dialog style={{ maxWidth: '512px', minWidth: '55%' }} header={requireOtp ? <span style={{ fontSize: '13px', fontWeight: 'bold' }}>{tawreedTranslationService.translate('lbl_otp_message')}</span> 
        : tawreedTranslationService.translate('lbl_document_files_add_invoice')}
        visible={visible} footer={<OrderDocumentUploadDialogFooter />} onHide={hide} draggable={false} position="top" closable={false} closeOnEscape={false}>
        <form className="formgrid grid" >
            <React.Fragment>
                {requireOtp && 
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <OtpInput
                        inputStyle={{ width: '100%', height: '50px' }}
                        numInputs={4}
                        isInputNum
                        separator={<span>-</span>}
                        value={dialogState.code}
                        onChange={(e: any) => {
                            setDialogState({...dialogState, code: e})
                        }}
                    />
                </div>
}
                <TawreedFormField title="lbl_files_file" className="field col-12 align" name="file">
                    <TawreedInputFile render='standalone' name="file" onChange={
                        (e: TawreedFile | undefined) => setDialogState({ ...dialogState, file: e && e.value instanceof File ? e.value : undefined })
                    } value={dialogState.file} />
                </TawreedFormField>

            </React.Fragment>
        </form>
    </Dialog>
    )
}