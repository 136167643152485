import React, { useMemo, useState } from "react";
import { TawreedForm, TawreedFormSubmitProps } from "../../../../../../common/components/form";
import { OrderDto, OrderItemDto, orderService as service } from "../../../domain";
import { OrderFormItems } from "../order-form-items";
import { GlobalContext } from "../../../../../../context";
import { TawreedAction, TawreedActionName } from "../../../../../../common/actions";
import { PrimeIcons } from "primereact/api";
import { OrderFormSummary } from "../order-form-summary";
import { OrderDocumentTable } from "../order-document-table";
import * as actions from "./actions";
import { OrderFormProps } from "./order-form.props";
import { OrderFormState } from "./order-form.state";
import { OrderFormInformation1 } from "./order-form-information-1";
import './order-form.scss';
import { AuthService, authService } from "../../../../../auth/domain";
import { PackagesDialog } from "./order-packages-popup";
import { tawreedTranslationService } from "../../../../../../common/translations";
import { Panel } from "primereact/panel";
import { FileService, TawreedFile } from "../../../../../upload";
import { OrderDeliverDocumentUploadDialog } from "./order-document-upload-dialog";
import { classNames } from "primereact/utils";
import { OrderNote } from "./order-note";
import { OrderNoteDialog } from "./order-note-dialog";
import { OrderDocumentUploadDialog } from "../order-upload-dialog";
import { Button } from "primereact/button";

export const OrderForm: React.FC<OrderFormProps> = ({ listener, orderId, returned, showBack }) => {

    // refs
    const confirmDialog = React.useRef<actions.OrderConfirmDialog>(null);
    const cancelDialog = React.useRef<actions.OrderCancelDialog>(null);
    const returnDialog = React.useRef<actions.OrderReturnDialog>(null);

    const userService: AuthService = useMemo(() => new AuthService(), []);
    const fileService: FileService = React.useMemo(() => new FileService(), []);


    // di
    const { constants: { constants }, auth: { user } } = React.useContext(GlobalContext);

    const [uploadFileDialog, setUploadFileDialog] = useState<boolean>(false);
    // states
    const [state, setState] = React.useState<OrderFormState>({
        loading: false,
        mode: 'Edit',
        form: {
            //
        },
        actions: [],
    });
    const [otpPopUp, setOtpPopUp] = useState({
        isVisable: false,
        otpCode: ''
    });

    const [packagesPopUp, setPackagesPopUp] = useState({
        isVisable: false,
    });

    const [deliverDocumentPopUp, setDeliverDocumentPopUp] = useState({
        isVisable: false
    });

    const [orderNotePopUp, setOrderNotePopUp] = useState({
        isVisable: false
    });

    React.useEffect(() => {
        const next = new Array<TawreedAction>();
        if (state.form.showConfirm) {
            const onConfirm = (_sender: any, _params: any, carrierId: number): Promise<void> => {
                return actions
                    .onConfirm(state.form.orderId!, carrierId, listener)
                    .then(res => {
                        if (res) {
                            setState(previous => {
                                return { ...previous, form: res }
                            })
                        }
                    });
            }
            next.push(new TawreedAction(TawreedActionName.OrdersConfirm, 'statefull', 'lbl_sales_orders_confirm', PrimeIcons.CHECK, onConfirm, 'p-button-text', { mode: 'advanced', ref: confirmDialog }));
        }
        if (state.form.showReadyForDelivery) {
            const onReady = () => {
                return new Promise<void>(() => {
                    setPackagesPopUp({ isVisable: true });
                });
            }
            next.push(new TawreedAction(TawreedActionName.OrdersReadyForDelivery, 'statefull', 'lbl_sales_orders_ready', PrimeIcons.CHECK, onReady, 'p-button-text', false));
        }

        if (state.form.showShip) {
            const onShip = () => {
                return actions
                    .onShip(state.form.orderId!, listener)
                    .then(res => {
                        if (res) {
                            setState(previous => {
                                return { ...previous, form: res }
                            })
                        }
                    });
            };
            next.push(new TawreedAction(TawreedActionName.OrdersShip, 'statefull', 'lbl_sales_orders_ship', PrimeIcons.SEND, onShip, 'p-button-text', true));
        }

        if (state.form.showDeliver) {
            const onDeliver = () => {
                if (state.form.payThroughUs && state.form.requiredOtp) {

                    return userService.sendOtp({ type: 2, key: state.form.customer?.contact?.mobileNo?.substring(2) || '' }).then(res => {
                        setDeliverDocumentPopUp({ isVisable: true });
                    });
                }
                else {
                    return new Promise<void>(() => {
                        setDeliverDocumentPopUp({ isVisable: true });
                    });
                }
            };
            next.push(new TawreedAction(TawreedActionName.OrdersDeliver, 'statefull', 'lbl_sales_orders_deliver', PrimeIcons.TABLE, onDeliver, 'p-button-text', false));
        }

        if (state.form.showReturn) {
            const onReturn = (_sender: any, _params: any, cancelReason: any): Promise<void> => {
                return actions
                    .onReturn(returned, state.form.orderId!,
                        cancelReason.reason, cancelReason.reasonAr, cancelReason.reasonEn, listener)
                    .then(res => {
                        if (res) {
                            setState(previous => {
                                return { ...previous, form: res }
                            })
                        }
                    });
            };
            next.push(new TawreedAction(TawreedActionName.OrdersReturn, 'statefull', 'lbl_sales_orders_return', PrimeIcons.REPLY, onReturn, 'p-button-text', { mode: 'advanced', ref: returnDialog }));
        }

        if (state.form.showCancel) {
            if (returned) {
                const onCancel = (): Promise<void> => {
                    return actions
                        .onReturnCancel(state.form.orderId!, listener)
                        .then(res => {
                            if (res) {
                                setState(previous => {
                                    return { ...previous, form: res }
                                })
                            }
                        });
                }
                next.push(new TawreedAction(TawreedActionName.OrdersCancel, 'statefull', 'lbl_sales_orders_cancel', PrimeIcons.TIMES, onCancel, 'p-button-text', true));
            } else {
                const onCancel = (_sender: any, _params: any, cancelReasonId: number): Promise<void> => {
                    return actions
                        .onCancel(state.form.orderId!, cancelReasonId, listener)
                        .then(res => {
                            if (res) {
                                setState(previous => {
                                    return { ...previous, form: res }
                                })
                            }
                        });
                }
                next.push(new TawreedAction(TawreedActionName.OrdersCancel, 'statefull', 'lbl_sales_orders_cancel', PrimeIcons.TIMES, onCancel, 'p-button-text', { mode: 'advanced', ref: cancelDialog }));

            }
        }

        if (state.form.statusId) {
            const onPrint = (report: string) => {
                return service.print(report)
                    .then(() => {
                    })
                    .catch(err => console.error(err));
            };

            if (state.form.commissionReportURI) {
                next.push(new TawreedAction(TawreedActionName.OrdersPrintStore, 'statefull', 'lbl_sales_orders_store_print', PrimeIcons.PRINT, () => onPrint(state.form.commissionReportURI!), 'p-button-text', false));
            }
            if (state.form.orderReportURI) {
                next.push(new TawreedAction(TawreedActionName.OrdersPrintCustomer, 'statefull', 'lbl_sales_orders_customer_print', PrimeIcons.PRINT, () => onPrint(state.form.orderReportURI!), 'p-button-text', false));
            }
            if (state.form.shipmentOrderReportURI) {
                next.push(new TawreedAction(TawreedActionName.OrdersPrintCustomer, 'statefull', 'lbl_sales_orders_shipment_print', PrimeIcons.PRINT, () => onPrint(state.form.shipmentOrderReportURI!), 'p-button-text', false));
            }
        }
        setState(previous => {
            return { ...previous, actions: next }
        });
        // eslint-disable-next-line
    }, [state.form, returned, listener]);

    React.useEffect(() => {
        let mounted = true;
        if (orderId) {
            if (mounted) {
                setState(previous => {
                    return { ...previous, loading: true, mode: 'Edit' }
                });
                service.getDetails(orderId, returned)
                    .then(res => {
                        setState(previous => {
                            return { ...previous, loading: false, form: res }
                        });
                    })
                    .catch(() => {
                        setState(previous => {
                            return { ...previous, loading: false }
                        });
                    });
            }
        } else {
            if (mounted) {
                setState(previous => {
                    return { ...previous, loading: false, mode: 'Create', form: {} }
                });
            }
        }
        return () => {
            mounted = false;
        };
    }, [orderId, returned]);

    const submit: TawreedFormSubmitProps<OrderDto> = {
        showButton: false,
        resetOnSubmit: false,
    }

    const onSaveItem = (item: OrderItemDto) => {
        console.log(item, "ASDSA");
        setState(previous => {
            return { ...previous, loading: true }
        });

        return service.updateItem(item)
            .then(res => {
                setState({ ...state, form: res, loading: false });
                if (listener) {
                    listener({ type: 'item-changes', sender: res });
                }
                return true;
            })
            .catch(() => {
                setState({ ...state, loading: false });
                return false;
            });
    }
    const addOrderFile = (file: TawreedFile, title: string, type: string) => {
        setState({ ...state, loading: true });
        service.createFile(file, title, orderId!, type).then((re) => {
            service.getDetails(orderId!, returned)
                .then(res => {
                    console.log("getdetails", res);
                    setState(previous => {
                        return { ...previous, loading: false, form: res }
                    });
                })
                .catch(() => {
                    setState(previous => {
                        return { ...previous, loading: false }
                    });
                });

        }).catch(() => {
            setState({ ...state, loading: false });

        });
    }
    const downloadOrderFile = async (fileId: string, name?: string) => {
        try {
            setState({ ...state, loading: true });

            await fileService.downloadS3(fileId, name);

        } catch (err) {
            console.log(err);
        }
        setState({ ...state, loading: false });

    }

    const onRequestCarrierChange = (): void => {
        const onCarrierChange = (_sender: any, _params: any, carrierId: number): Promise<void> => {
            return actions
                .onCarrierChange(state.form.orderId!, carrierId, listener)
                .then(res => {
                    if (res) {
                        setState(previous => {
                            return { ...previous, form: res }
                        })
                    }
                });
        }
        const action = new TawreedAction(TawreedActionName.OrdersConfirm, 'statefull', 'lbl_sales_orders_carrier_change', PrimeIcons.CHECK, onCarrierChange, undefined, { mode: 'advanced', ref: confirmDialog });
        action.execute().then();
    }

    const accept = (data: { file: string | undefined, code: string | undefined }) => {
        setState({ ...state, loading: true });
        setDeliverDocumentPopUp({ isVisable: false })
        if (state.form.payThroughUs && state.form.requiredOtp) {
            return authService.veifyOtp({ type: 2, key: state.form.customer?.contact?.mobileNo?.substring(2) || '', code: +data.code! })
                .then(res => {
                    return actions
                        .onDeliver({
                            orderId: state.form.orderId!,
                            otp: res.data,
                            file: data.file
                        }, listener)
                        .then(res => {
                            if (res) {
                                setState(previous => {
                                    return { ...previous, loading: false, form: res }
                                });
                            }
                            else {
                                setState(previous => {
                                    return { ...previous, loading: false }
                                });
                            }
                        }).catch(() => {
                            setState(previous => {
                                return { ...previous, loading: false }
                            });
                        });
                });

        }
        else {
            return actions
                .onDeliver({ orderId: state.form.orderId!, file: data.file }, listener)
                .then(res => {
                    if (res) {
                        setState(previous => {

                            return { ...previous, loading: false, form: res }
                        });
                    }
                    else {
                        setState(previous => {
                            return { ...previous, loading: false }
                        });
                    }
                }).catch(() => {
                    setState(previous => {
                        return { ...previous, loading: false }
                    });
                });
        }

    }

    const acceptNote = (note: string | undefined) => {
        setState({ ...state, loading: true });
        setOrderNotePopUp({ isVisable: false })
        service.addNote({ orderId: state.form.orderId, note: note })
            .then(res => {
                if (res) {
                    setState(previous => {
                        return { ...previous, loading: false, form: res }
                    });
                }
                else {
                    setState(previous => {
                        return { ...previous, loading: false }
                    });
                }
            }).catch(() => {
                setState(previous => {
                    return { ...previous, loading: false }
                });
            });

    }

    const uploadFile = (data: { file: File, title: string, type: string }) => {
        let file: TawreedFile = { fileName: data.file.name, value: data.file, type: data.type };
        addOrderFile(file, data.title, data.type);

    }
    return (
        <React.Fragment>
            <TawreedForm title={returned ? 'lbl_sales_returned_orders' : 'lbl_sales_order'}
                dataKey="orderId" data={state.form}
                submit={submit}
                loading={state.loading} mode={state.mode} actions={state.actions} useBack={showBack ? '..' : undefined}
                className="grid form-grid">

                {/* <Panel header={<span style={{fontSize:'1.5rem', display:'block', fontWeight:'bold'}}>{tawreedTranslationService.translate('lbl_sales_orders_o_info_details')}</span>} style={{width:'100%'}} className="ml-2 mr-2"> */}
                <React.Fragment>
                    <actions.OrderConfirmDialog ref={confirmDialog}
                        salesorderId={orderId} payThroughUs={state.form.payThroughUs} partner={state.form.store} carrier={state.form?.carrier ? state.form.carrier.carrierId : undefined} customer={state.form.customer ?? undefined} />
                    <actions.OrderCancelDialog ref={cancelDialog} />
                    <actions.OrderReturnDialog ref={returnDialog} />
                </React.Fragment>

                {
                    user &&

                    <div className="col-12">
                        <OrderFormInformation1 salesOfferTitle={state.form.salesOfferTitle} className="" returned={returned} orderStatuses={constants?.orderStatuses} salesOrderId={state.form.salesOrderId} showShipment={state.form.showShipment} editShipment={state.form.editShipment} requestCarrierChange={() => onRequestCarrierChange()} />
                    </div>
                }
                <Panel header={<div className="flex-column">
                    <span style={{ fontSize: '1.5rem', display: 'block', fontWeight:'600' }}>
                        {tawreedTranslationService.translate('lbl_sales_orders_o_info_items')}
                    </span>
                    {state.form.showEdit && <span style={{ fontSize: '13px', fontWeight: 'normal', padding: '4px' }}>
                        {tawreedTranslationService.translate('lbl_placed_note')}
                    </span>}
                </div>} className={"col-12"}>
                    <OrderFormItems
                        className="col-12"
                        returned={returned}
                        disabled={state.loading}
                        showEdit={state.form.showEdit}
                        items={state.form.items}
                        showNote={state.form.showConfirm || false}
                        onSave={onSaveItem} />

                    <OrderFormSummary className="col-12"
                        returned={returned}
                        subTotal={state.form.subTotal}
                        shippingTotal={state.form.shippingTotal}
                        discount={state.form.totalDiscount}
                        total={state.form.total}
                        totalTax={state.form.totalTax}
                        grossTotal={state.form.grossTotal}
                    />
                </Panel>

                {/* </Panel> */}
            </TawreedForm>
            <PackagesDialog
                visible={packagesPopUp.isVisable}
                listener={listener}
                orderId={state.form.orderId!}
                hide={() => {
                    setPackagesPopUp({ isVisable: false })
                }}
            />
            <OrderDeliverDocumentUploadDialog
                visible={deliverDocumentPopUp.isVisable}
                accept={accept}
                requireOtp={state.form.payThroughUs! && state.form.requiredOtp!}
                payThroughUs={state.form.payThroughUs!}
                hide={() => {
                    setDeliverDocumentPopUp({ isVisable: false })
                }} />
            <OrderNoteDialog
                visible={orderNotePopUp.isVisable}
                accept={acceptNote}
                hide={() => {
                    setOrderNotePopUp({ isVisable: false });
                }}
            />
            <Panel header={<div className="flex-column">
                    <span style={{ fontSize: '1.5rem', fontWeight:'600'}}>
                        {tawreedTranslationService.translate('lbl_order_notes')}
                    </span>
                </div>} className="mt-2"

                icons={
                    <React.Fragment>
                        <div className="formgroup-inline">
                            <Button className="p-button-text p-button-success" label={tawreedTranslationService.translate('lbl_add')}
                                icon={PrimeIcons.PLUS} onClick={() => setOrderNotePopUp({ isVisable: true })} />
                        </div>
                    </React.Fragment>
                }
            >
                {
                    state.form.notes?.map((note) => {
                        return <React.Fragment key={note.salesOrderNoteId}>
                            <OrderNote salesOrderNoteId={note.salesOrderNoteId}
                                userName={note.userName} note={note.note} createdAt={note.createdAt}
                                partner={note.partner} email={note.email} mobileNo={note.mobileNo}
                                salesOrderId={note.salesOrderId} userId={note.userId}></OrderNote>
                            <br />
                        </React.Fragment>
                    })
                }
            </Panel>
            <Panel icons={
                <React.Fragment>
                    <div className="formgroup-inline">
                        <Button className="p-button-text p-button-success" label={tawreedTranslationService.translate('lbl_add')}
                            icon={PrimeIcons.PLUS} onClick={() => setUploadFileDialog(true)} />
                    </div>
                </React.Fragment>
            } header={<div className="flex-column">
            <span style={{ fontSize: '1.5rem', fontWeight:'600'}}>
                {tawreedTranslationService.translate('lbl_Doument_files')}
            </span>
        </div>} className="mt-2">
                <OrderDocumentTable data={state.form?.fiels ?? []} addDocumentFile={addOrderFile} downloadDocumentFile={downloadOrderFile} loading={state.loading}></OrderDocumentTable>
            </Panel>
            <OrderDocumentUploadDialog
                visible={uploadFileDialog}
                hide={() => setUploadFileDialog(false)}
                accept={uploadFile}
            ></OrderDocumentUploadDialog>
        </React.Fragment>
    );
}
