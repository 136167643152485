import React, { useEffect, useState } from "react";
import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { OrderItemDto } from "../../../domain";
import { tawreedTranslationService } from "../../../../../../common/translations";
import { TawreedFormField, TawreedInputNumber } from "../../../../../../common";

export interface OrderItemProps {
    item:OrderItemDto,
    onSave: (item: OrderItemDto) => void;
    visible: boolean;
    onHide: ()=>void;
}

export const OrderItemUpdateDialog: React.FC<OrderItemProps> = (props) => {

    const [quantity,setQuantity] = useState<number>(props.item ? props.item.quantity! :  0);
    const [bouns,setBonus] = useState<number>(props.item ? props.item.bonus! :  0);
    const [discount,setDiscount] = useState<number>(props.item ? props.item.discountBonus! :  0);


    const DialogFooter = () => {
        return (
            <div>
                <Button className="p-button-text p-button-text p-button-secondary" label={tawreedTranslationService.translate('lbl_close')} icon={PrimeIcons.TIMES} onClick={() => onHide(false)} />
                <Button className="p-button-text p-button-text" label={tawreedTranslationService.translate('lbl_okay')}
                    icon={PrimeIcons.CHECK}

                    disabled={!quantity || quantity> props.item.quantityOrdered!}
                    onClick={() => { props.onSave({...props.item, quantity:quantity, bonus:bouns, discountBonus: discount}); props.onHide()  }}
                />
            </div>
        );
    }

    useEffect(()=>{
        if(props.item){
            setQuantity(props.item.quantity!)
            setBonus(props.item.bonus!)
            setDiscount(props.item.discountBonus!)
        }
        else{
            setQuantity(0);
            setBonus(0)
            setDiscount(0)
        }
    },[props.item]);

    const onHide = (res: boolean) => {
        if (props.onHide) {
            props.onHide();
        }
    }

    return (
        <Dialog visible={props.visible} onHide={() => onHide(false)}
            header={tawreedTranslationService.translate('lbl_checkout_form')} footer={<DialogFooter />}
            position="top" breakpoints={{ '960px': '75vw', '640px': '100vw' }} style={{ width: '40vw' }} draggable={false} closable={false} focusOnShow closeOnEscape={false}>

            <div className="form-grid grid">
               
                <TawreedFormField name="quantity" title="lbl_sales_orders_o_info_items_quantity" className="field col-12">
                    <TawreedInputNumber
                        name="quantity"
                        render="standalone"
                        value={quantity}
                        onChange={(e) => {
                            setQuantity(e.value ?? 0);
                        }} />

                </TawreedFormField>
                <TawreedFormField name="bouns" title="lbl_store_products_bonus" className="field col-12">
                    <TawreedInputNumber
                        name="bouns"
                        render="standalone"
                        value={bouns}
                        onChange={(e) => {
                            setBonus(e.value ?? 0);
                        }} />

                </TawreedFormField>
                <TawreedFormField name="additionalDiscounnt" title="lbl_additional_discount" className="field col-12">
                    <TawreedInputNumber
                        name="additionalDiscounnt"
                        render="standalone"
                        value={discount}
                        onChange={(e) => {
                            setDiscount(e.value ?? 0);
                        }} />

                </TawreedFormField>

            </div>
        </Dialog>
    );
}
