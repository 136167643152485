import React,{FC,useMemo, useRef,useState} from 'react';
import { TawreedTable, TawreedTableProps } from "../../../../common/components/table";
import { tawreedTranslationService } from "../../../../common/translations";
import { CssUtils } from "../../../../helpers/css";
import { PrimeIcons } from "primereact/api";
import { TawreedAction, TawreedActionName } from '../../../../common/actions';
import { Routes } from '../../../../router';
import { useHistory } from 'react-router-dom';
import { GlobalContext } from '../../../../context';
import { SalesOfferService } from '../../domain/services';
import { salesOfferDto } from '../../domain/dtos';
import { TawreedTableFilters } from '../../../../common/components/table/filters';
import { Button } from 'primereact/button';
import {buildActiveTawreedTableFormatter} from '../../../../common/components/table/filters/active-table-formatter';


export interface SalesOfferProps {
    className?: string;
    disabled?: boolean;
    zoneId?: number;
}
export interface CarFormSalesOfferState {
    isVisible: boolean;
    saleOfferId?: number;
}

export const SalesOffer: FC<SalesOfferProps> = (props)=>{
    const service: SalesOfferService = useMemo(() => new SalesOfferService(), []);
    const ref = useRef<any>(null);
    const history = useHistory();
    const { auth: { user }, constants: { constants } } = React.useContext(GlobalContext);
    const [reload, setReload] = useState(false);

    const [state, setState] = useState<CarFormSalesOfferState>({
        isVisible: false,
        saleOfferId: undefined,
    });
    const onNew = (): Promise<void> => {
        return new Promise((resolve) => {
            history.push(Routes.SalesOfferNew);
            resolve();
        })
    }
    const onActivate = (id: number,value:boolean): Promise<void> => {
        return new Promise((resolve, reject) => {
            setReload(false);
            if(value){
                service.activateAll([id])
                    .then(() => {
                        setReload(true);
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            }else{
                service.deactivateAll([id])
                .then(() => {
                    setReload(true);
                    resolve();
                })
                .catch(err => {
                    reject(err);
                });
            }
        });
    };
    const onDelete = (id:number): Promise<void> => {
        return new Promise((resolve, reject) => {
            setReload(true);
            return service.deleteAll([id]).then(() => setReload(true)).catch(err => console.error(err));
            
        })
    };
    const onForm = (sender?: salesOfferDto): Promise<void> => {
        return new Promise((resolve) => {
            history.push(Routes.SalesOfferDetails.replace(':id', sender!.salesOfferId.toString()));
            resolve();
        })
    };

    const tawreed: TawreedTableProps = {
        header: true,
        pagination: true,
        ds: {
            mode: 'lazy',
            onSearch: (q) => service.searchSalesoffer(q)
        },
        reload: reload,
        dataKey: 'salesOfferId',
        name: 'tbl.sales.offer',
        title: 'lbl_sales_offer_list_title',
        columns: [
            {
                field: "title",
                header: "lbl_sales_offer_title",
                sortField: 'title',
                sortable: true,
                filterField: 'title',
                style: CssUtils.width('20%'),
            },
            {
                field: "description",
                header: "lbl_sales_offer_description",
                sortField: 'description',
                sortable: true,
                filterField: 'description',
                style: CssUtils.width('20%'),
            },
            {
                field: "expirationDate",
                header: "lbl_sales_offer_expire_date",
                sortField: 'expirationDate',
                sortable: true,
                style: CssUtils.width('10%'),
            },
            {
                field: "priceListName",
                header: "lbl_sales_offer_price_list",
                sortField: 'priceListName',
                style: CssUtils.width('20%'),
            },
            {
                field: "active",
                header: "lbl_product_active",
                filter:true,
                sortable:true,
                ...buildActiveTawreedTableFormatter('active', undefined,(id,value)=>{
                    onActivate(id,value);
                },"salesOfferId"),
                style: CssUtils.width(user?.roleCode !== 'STORE_ADMIN' ? '15%' : '20%'),
            },
            {
                field: "actions",
                header: "lbl_actions",
                body: (options: any) => {
                    return <React.Fragment>
                        <Button
                            type="button"
                            style={{height: "35px" }}
                            icon={PrimeIcons.EYE}
                            className="p-button-success p-button-text"
                            tooltip={tawreedTranslationService.translate('lbl_info')}
                            onClick={() => {
                                history.push(Routes.SalesOfferDetails.replace(':id', options!.salesOfferId.toString()));
                                
                            }} />
                            <Button
                            type="button"
                            style={{ height: "35px" }}
                            icon={PrimeIcons.TRASH}
                            className="p-button-danger p-button-text"
                            tooltip={tawreedTranslationService.translate('lbl_delete')}
                            onClick={() => {
                                onDelete(options!.salesOfferId);
                            }} />
                    </React.Fragment>
                },
                style: CssUtils.width('20%'),
            }

        ],
        sort: {
            sortMode: 'single',
            initial: {
                sortField: 'title',
                sortOrder: 1,
                multiSortMeta: undefined,
            },
        },
        toolbar: {
            actions: [

                new TawreedAction(TawreedActionName.StoreProductsCreate, 'stateless', 'lbl_sales_offer_new', PrimeIcons.PLUS, onNew),
                //new TawreedAction(TawreedActionName.StoreProductsDelete, 'statefull', (e) => tawreedTranslationService.translate('lbl_delete_all', e?.length ?? 0), PrimeIcons.TRASH, onDelete, 'p-button-danger', 'confirmDelete'),
                //new TawreedAction(TawreedActionName.StoreProductsActivate, 'statefull', (e) => tawreedTranslationService.translate('lbl_activate_all', e?.length ?? 0), PrimeIcons.CHECK, onActivate, 'p-button-secondary', 'confirm'),
                //new TawreedAction(TawreedActionName.StoreProductsDeactivate, 'statefull', (e) => tawreedTranslationService.translate('lbl_deactivate_all', e?.length ?? 0), PrimeIcons.CHECK, onDeactivate, 'p-button-secondary', 'confirm'),
            ],
        },

    }
    return(
        <React.Fragment>
            <TawreedTable {...tawreed} />

            {/* <div style={{ display: 'none' }}>
                <Button type="submit"
                    className="w-full"
                    ref={ref}
                    disabled={props.disabled}
                    label={tawreedTranslationService.translate('lbl_add_to_cart')} />
            </div>
            {
                state.isVisible &&
                state.saleOfferId &&
                <SalesOfferDetailsDialog
                    visible={state.isVisible}
                    salesOfferId={state.saleOfferId}
                  
                    hide={() => { setState({ saleOfferId: undefined, isVisible: false }) }}
                    accept={() => {
                        if (ref.current && ref.current instanceof HTMLButtonElement) {
                            ref.current.click();
                            setState({ saleOfferId: undefined, isVisible: false });
                        } else {
                            console.warn('ref.current && ref.current instanceof HTMLButtonElement');
                        }
                    }}
                ></SalesOfferDetailsDialog>} */}
        </React.Fragment>
    );
}