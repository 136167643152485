import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import { FileUpload, FileUploadBeforeUploadParams, FileUploadErrorParams, FileUploadHandlerParam, FileUploadSelectParams, FileUploadUploadParams } from 'primereact/fileupload';
import React, { FC, useEffect, useRef, useState } from 'react'
import { tawreedTranslationService } from '../../../../../common/translations';
import { TawreedResponseStatusCode } from '../../../../../http';
import { PartnerType } from "../../../../partners/data";
import { TawreedCheckbox, TawreedFormField, TawreedInputSwitch, TawreedMenuDropdown } from "../../../../../common/components/form";
import { AuthUtils } from "../../../../auth/domain";
import { toastService } from "../../../../notifications/domain";
import { CategoriesDropdown } from '../../../products/presentaion/components/categories-dropdown';
import { TawreedMenuMultiSelect } from '../../../../../common/components/form/controls/multiselect/multiselect-menu';
import { PrimeIcons } from 'primereact/api';
import { NoteDialog } from './notesDialog';

export interface ImportProductsDialogProps {
    visible: boolean;

    hide: () => void;

    accept: string;

    uploadUrl: string;

    isStore: boolean;

    partnerId: number;
}

export const ImportProductsDialog: FC<ImportProductsDialogProps> = ({ visible, hide, accept, uploadUrl, isStore, partnerId }) => {

    const file = useRef<FileUpload>(null);
    const [dialogState, setDialogState] = useState<{ categoryId: number | undefined, storeId: number | undefined, priceListId: number | undefined, fileName: string | undefined, oldBatch: boolean, loading: boolean, discountType?: string, isAddBaseProduct: boolean, }>({
        storeId: isStore ? partnerId : undefined,
        categoryId: undefined,
        priceListId: undefined,
        discountType: undefined,
        fileName: undefined,
        loading: false,
        oldBatch: false,
        isAddBaseProduct: true,
    });
    const [upload, setUpload] = useState<boolean>(false);
    const [popUp, setPopUp] = useState(false);
    useEffect(() => {
        if (upload) {
            file.current?.upload();
        }
    }, [upload]);

    function onBeforeSend(e: FileUploadBeforeUploadParams): void {
        if (!dialogState.storeId || !dialogState.priceListId || !dialogState.discountType) {
            throw new Error('storeId or categoryId');
        }
        if (!upload) {
            file.current?.clear();
            throw new Error('storeId or categoryId');
        }
        e.formData.set('storeId', dialogState.storeId.toString());
        // e.formData.set('categoryId', dialogState.categoryId.toString());
        e.formData.set('priceListId', dialogState.priceListId.toString());
        e.formData.set("discountType", dialogState.discountType.toString());

        e.formData.set('oldBatch', dialogState.oldBatch.toString());
        e.formData.set('isAddBaseProduct', dialogState.isAddBaseProduct.toString());
        e.xhr.setRequestHeader('Authorization', 'Bearer ' + AuthUtils.current().getToken().token);
        e.xhr.responseType = 'blob';
        e.xhr.timeout = 5 * 60 * 1000;
        setDialogState({ ...dialogState, loading: true });
    }

    function onUpload(_e: FileUploadHandlerParam): void {
    }

    function onSelect(e: FileUploadSelectParams): void {
        setDialogState({ ...dialogState, fileName: e.files && e.files.length ? e.files[0].name : undefined });
    }

    function onUploaded(e: FileUploadUploadParams): void {
        let response: any;
        try {
            response = JSON.parse(e.xhr.response || '{}');
            if (Object.keys(response).length && response.status !== TawreedResponseStatusCode.Success) {
                const error = response.error || 'msg_unknown_error';
                toastService.show({ severity: 'error', summary: tawreedTranslationService.translate('lbl_failed'), detail: tawreedTranslationService.translate(error) });
                setDialogState({ fileName: undefined, storeId: undefined, categoryId: undefined, priceListId: undefined, loading: false, oldBatch: false, isAddBaseProduct: true });
                setUpload(false);
                return;
            }
        } catch (error) {

            response = e.xhr.response;

            toastService.show({ severity: 'success', summary: tawreedTranslationService.translate('lbl_success'), detail: tawreedTranslationService.translate('msg_upload_completed_successfully') });
            setDialogState({ fileName: undefined, storeId: undefined, categoryId: undefined, priceListId: undefined, loading: false, oldBatch: false, isAddBaseProduct: true });
            setUpload(false);
            setTimeout(() => hide(), 500);
            // const href = URL.createObjectURL(new Blob([response]));

            // // create "a" HTLM element with href to file & click
            // const link = document.createElement('a');
            // link.href = href;
            // link.setAttribute('download', 'Rejected.csv'); //or any other extension
            // document.body.appendChild(link);
            // link.click();

            // // clean up "a" element & remove ObjectURL
            // document.body.removeChild(link);
            // URL.revokeObjectURL(response);
        }
    }

    function onError(e: FileUploadErrorParams): void {
        const error = ((typeof (e.xhr.response) === 'string' ? JSON.parse(e.xhr.response) : e.xhr.response) || { error: 'msg_unknown_error' }).error;
        toastService.show({ severity: 'error', summary: tawreedTranslationService.translate('lbl_failed'), detail: tawreedTranslationService.translate(error) });
        setDialogState({ ...dialogState, loading: false });
        setUpload(false);
    }

    const ImportProductsDialogFooter = () => {
        return (<div>
            <Button label={tawreedTranslationService.translate('lbl_cancel')} icon="pi pi-times" onClick={hide} className="p-button-text" disabled={dialogState.loading} />
            <Button label={tawreedTranslationService.translate('lbl_accept')} icon="pi pi-check"
                onClick={() => { setUpload(true); }}// file.current?.upload();}}
                disabled={!dialogState || !dialogState.storeId || !file.current?.state.files || !file.current?.state.files.length || dialogState.loading || !dialogState.priceListId}
                loading={dialogState.loading} />
        </div>);
    }

    return (
        <Dialog style={{ maxWidth: '512px' }} header={tawreedTranslationService.translate('lbl_store_products_import')} visible={visible} footer={<ImportProductsDialogFooter />} onHide={hide} draggable={false} position="top" closable={false} closeOnEscape={false}>
            <form className="formgrid grid">
                

                <TawreedFormField name="priceListId" title="lbl_price_scheme_price_list_name" className="field col-12">
                    <TawreedMenuDropdown menu={"priceLists"} render="standalone" value={dialogState.priceListId} onChange={(e) => setDialogState({ ...dialogState, priceListId: e.value })} />
                </TawreedFormField>

                <TawreedFormField name="discountType" title="lbl_price_scheme_discountType" className="field col-12">
                    <TawreedMenuDropdown menu={"discountTypes"} render="standalone" value={dialogState.discountType} onChange={(e) => setDialogState({ ...dialogState, discountType: e.value })} />
                </TawreedFormField>

                <i className={"mt-1 mr-2 " + PrimeIcons.INFO_CIRCLE} style={{ cursor: 'pointer' }} onClick={() => setPopUp(true)}></i>
                <TawreedFormField name="oldBatch" title="lbl_old_batch" className="field-checkbox col-12 md:col-6" flip>
                    <React.Fragment>

                        <TawreedCheckbox render="standalone" checked={dialogState.oldBatch} onChange={() => {
                            setDialogState({ ...dialogState, oldBatch: !dialogState.oldBatch })
                        }} />


                    </React.Fragment>
                </TawreedFormField>

                {/* <TawreedFormField name="isAddBaseProduct" title="lbl_add_base_product" className="field-checkbox col-12 md:col-12" flip>
                    <React.Fragment>

                        <TawreedCheckbox render="standalone" checked={dialogState.isAddBaseProduct} onChange={() => {
                            setDialogState({ ...dialogState, isAddBaseProduct: !dialogState.isAddBaseProduct })
                        }} />


                    </React.Fragment>
                </TawreedFormField> */}
                <div className="field col-12 mt-2">
                    <label htmlFor="file">{tawreedTranslationService.translate('lbl_file')}</label>
                    <FileUpload
                        ref={file}
                        mode="basic"
                        name="file"
                        url={uploadUrl}
                        accept={accept}
                        onUpload={onUploaded} onError={onError}
                        auto={false} uploadHandler={onUpload} onSelect={onSelect}
                        onBeforeSend={onBeforeSend}
                        disabled={!dialogState || !dialogState.storeId || dialogState.loading} />
                </div>
            </form>
            <NoteDialog visible={popUp} hide={() => setPopUp(false)} />
        </Dialog>
    )
}
