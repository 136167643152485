import { TawreedRoleName, UserPermissions } from "../../domain";
import { Routes } from "../../../../router";
import { TawreedActionName } from "../../../../common/actions";

export class PermissionsRepository {

    private readonly administrator: UserPermissions = {
        actions: [],
        routes: []
    }
    private readonly sysAdmin: UserPermissions = {
        actions: [],
        routes: []
    }

    private readonly manager: UserPermissions = {
        actions: [],
        routes: []
    }

    private readonly sales: UserPermissions = {
        actions: [],
        routes: []
    }

    private readonly delivery: UserPermissions = {
        actions: [],
        routes: []
    }

    private readonly store: UserPermissions = {
        actions: [
            TawreedActionName.ReportsExport,
            TawreedActionName.ReportsGenerate,

            TawreedActionName.FinanceDocumentsCreate,

            TawreedActionName.DashboardCardsSales,
            TawreedActionName.DashboardCardsOrdersPlaced,
            TawreedActionName.DashboardCardsOrdersProcessing,
            TawreedActionName.DashboardCardsOrdersShipped,
            TawreedActionName.DashboardCardsOrdersReturnShipped,
            TawreedActionName.DashboardChartOrdersTotals,
            TawreedActionName.DashboardCardBalance,
            TawreedActionName.DashboardCardsOrdersReadyForDelivery,
            TawreedActionName.DashboardChartOrdersPerZone,
            TawreedActionName.DashboardChartOrdersPerState,

            TawreedActionName.PartnersDetails,

            TawreedActionName.PartnerInformation,
            TawreedActionName.ProductsDetails,


            TawreedActionName.StoreProductsCreate,
            TawreedActionName.StoreProductsDetails,
            TawreedActionName.StoreProductsDelete,
            TawreedActionName.StoreProductsSubmit,
            TawreedActionName.StoreProductsStageImport,
            TawreedActionName.StoreProductsStageApprove,
            TawreedActionName.StoreProductsStageReject,
            TawreedActionName.StoreProductsDownloadTemplate,
            TawreedActionName.StoreProductsActivate,
            TawreedActionName.StoreProductsDeactivate,

            TawreedActionName.CartsCreate,
            TawreedActionName.CartsDetails,
            TawreedActionName.CartsUpdate,
            TawreedActionName.CartsStoreCartDelete,
            TawreedActionName.CartsStoreCartItemDelete,
            TawreedActionName.OrdersPartnersDetails,
            TawreedActionName.OrdersDetails,
            TawreedActionName.OrdersConfirm,
            TawreedActionName.OrdersShip,
            TawreedActionName.OrdersDeliver,
            TawreedActionName.OrdersCancel,
            TawreedActionName.OrdersReturn,
            TawreedActionName.OrdersPrintStore,
            TawreedActionName.OrdersPrintCustomer,
            TawreedActionName.OrdersEditQuantity,
            TawreedActionName.OrderEditDiscountValue,
            TawreedActionName.OrdersEditBonus,
            TawreedActionName.OrdersEditRetailPrice,
            TawreedActionName.OrdersEditDiscountPercent,
            TawreedActionName.OrdersPartnersDetails,
            TawreedActionName.OrdersPartnersShipAll,
            TawreedActionName.OrdersPartnersReturnAll,
            TawreedActionName.OrdersPartnersPrintAll,
            TawreedActionName.OrdersPartnersPrint,
            TawreedActionName.OrdersReadyForDelivery,
            TawreedActionName.OrdersReturnCreate,

            TawreedActionName.PartnersFilesDownload,

            TawreedActionName.UsersChangePassword,
            TawreedActionName.ImpoerHelperDetails,
            TawreedActionName.ImportUploadTask



        ],
        routes: [
            Routes.Login,
            Routes.Notifications,
            Routes.TawreedError,
            Routes.Dashboard,
            Routes.PartnersCustomersDetails,
            Routes.PartnersStoresDetails,

            Routes.ImportStoreProductsSearch,
            Routes.StoreProductsSearch,
            Routes.SellerStoreProductSearch,
            Routes.StoreProductsNew,
            Routes.StoreProductsDetails,
            Routes.ProductsDetails,

            Routes.UnverifiedProductsDetails,

            Routes.SalesCartsSearch,
            Routes.SalesCartsNew,
            Routes.SalesCartsDetails,

            Routes.SalesOrdersSearch,
            Routes.SalesOrdersDetails,
            Routes.SalesOrdersPlacedSearch,
            Routes.SalesOrdersPlacedDetails,
            Routes.SalesOrdersProcessingSearch,
            Routes.SalesOrdersProcessingDetails,
            Routes.SalesOrdersShippedSearch,
            Routes.SalesOrdersReadySearch,
            Routes.SalesOrdersReadyDetails,
            Routes.SalesOrdersShippedDetails,

            Routes.ReportsFinancialDocuments,
            Routes.ReportsFinancialStatementsPerPartner,
            Routes.ReportsSalesOrders,
            Routes.ReportsInvoices,
            Routes.UsersDetails,
            Routes.ImportHelper,
            Routes.ImportHelperDetails,
            Routes.SalesOfferSearch,
            Routes.SalesOfferNew,
            Routes.SalesOfferDetails,


        ],
    }

    private readonly customer: UserPermissions = {
        actions: [],
        routes: []
    }

    private readonly topup: UserPermissions = {
        actions: [],
        routes: []
    }

    private readonly finance: UserPermissions = {
        actions: [],
        routes: []
    }
    private readonly walletAdmin: UserPermissions = {
        actions: [],
        routes: []
    };
    private readonly catManager: UserPermissions = {
        actions: [],
        routes: []
    };
    private readonly storeDelivery: UserPermissions = {
        actions: [
            TawreedActionName.ReportsExport,

            TawreedActionName.DashboardChartOrders,
            TawreedActionName.PartnersFilesDownload,

            TawreedActionName.PartnersDetails,



            TawreedActionName.OrdersDetails,
            TawreedActionName.OrdersShip,
            TawreedActionName.OrdersPartnersShipAll,
            TawreedActionName.OrdersPrintStore,
            TawreedActionName.OrdersPrintCustomer,
            TawreedActionName.OrdersPartnersDetails,
            TawreedActionName.OrdersPartnersPrintAll,
            TawreedActionName.OrdersPartnersPrint,
            TawreedActionName.OrdersDeliver,
            TawreedActionName.OrdersCancel,
            TawreedActionName.UsersChangePassword,


        ],
        routes: [
            Routes.Login,
            Routes.Notifications,
            Routes.TawreedError,
            Routes.Dashboard,


            Routes.SalesOrdersSearch,
            Routes.SalesOrdersDetails,


            Routes.SalesOrdersShippedSearch,
            Routes.SalesOrdersShippedDetails,

            Routes.SalesOrdersReadySearch,
            Routes.SalesOrdersReadyDetails,


            Routes.UsersDetails



        ],
    }



    public getPermissions(role: TawreedRoleName): Promise<UserPermissions> {
        return new Promise<UserPermissions>((resolve, reject) => {

            switch (role) {
                case "ADMIN":
                    return resolve(this.administrator);
                case "SYS_ADMIN":
                    return resolve(this.sysAdmin);
                case "WALLET_ADMIN":
                    return resolve(this.walletAdmin);
                case "CATMANAGER":
                    return resolve(this.catManager);
                case "STORE_DELIVERY":
                    return resolve(this.storeDelivery);
                case "MANAGER":
                    return resolve(this.manager);
                case "SALES":
                    return resolve(this.sales);
                case "DELIVERY":
                    return resolve(this.delivery);
                case "STORE_ADMIN":
                    return resolve(this.store);
                case "CUSTOMER_ADMIN":
                    return resolve(this.customer);
                case "TOPUP_ADMIN":
                    return resolve(this.topup);
                case "FINANCE":
                    return resolve(this.finance);
                default:
                    return reject('MSG_ROLENAME_UNKNOWN');
            }
        });
    }
}
