import React from "react";
import { ColumnFilterElementTemplateOptions, ColumnFilterMatchModeType } from "primereact/column";
import { TriStateCheckbox } from "primereact/tristatecheckbox";
import { DateUtils } from "../../../../helpers/date";
import classNames from "classnames";
import { Dropdown } from "primereact/dropdown";
import { tawreedTranslationService } from "../../../translations";
import { MultiSelect } from "primereact/multiselect";
import { Tag } from "primereact/tag";
import { TawreedCalendar } from "../../form";
import { ObjectUtils } from "../../../../helpers/object";

const TawreedTableDateRangeFilter = (field: string, viewMode: 'time' | 'date' | 'datetime') => {

    return {
        body: (rowData: any) => {
            if (rowData[field]) {
                return DateUtils.format(rowData[field], viewMode);
            }
            return rowData[field];
        },
        filterElement: (options: ColumnFilterElementTemplateOptions) => (
            <TawreedCalendar render="standalone"
                value={options.value}
                readOnlyInput
                onChange={(e) => {
                    options.filterCallback(e.value, options.index);
                }} />
        ),
        // showFilterMatchModes: false,
        filterMatchMode: 'between' as ColumnFilterMatchModeType,
        filterType: 'between',
        dataType: 'date',
    }
};

const TawreedTableBooleanFilter = (field: string, validMsg: string = 'lbl_active', inValidMsg: string = 'lbl_not_active', allMsg: string = 'lbl_all', reverse: boolean = false) => {

    allMsg = tawreedTranslationService.translate(allMsg);
    validMsg = tawreedTranslationService.translate(validMsg);
    inValidMsg = tawreedTranslationService.translate(inValidMsg);

    return {
        body: (rowData: any) => {
            let valid = ObjectUtils.getNested(rowData, field);
            let style = { background: valid ? '#3cab3e' : '#E80A0A' }
            if (reverse)
                style = { background: !valid ? '#3cab3e' : '#E80A0A' }
            return <Tag rounded

                style={{ width: '5rem', ...style }}
                value={valid ? validMsg : inValidMsg} />
        },
        filterElement: (options: ColumnFilterElementTemplateOptions) => {

            let label = allMsg;
            if (options.value === true) {
                label = validMsg;
            } else if (options.value === false) {
                label = inValidMsg;
            }

            return (
                <div className="">
                    <TriStateCheckbox value={options.value} onChange={(e) => options.filterApplyCallback(e.value)} />
                    <label className="ml-1">{label}</label>
                </div>
            )
        },
        showFilterMatchModes: false,
        filterMatchMode: 'equals' as ColumnFilterMatchModeType,
        filterType: 'equals',
    }
};

const TawreedTableDropdownBooleanFilter = (field: string, items: any[], valid: any[], optionValue: string = "value", optionLabel: string = "label",body?: (rowData: any, valid: boolean,id:number) => React.ReactElement, field_id:string = "id") => {
    return {
        body: (rowData: any) => {
            const value = ObjectUtils.getNested(rowData, field);
            const id = ObjectUtils.getNested(rowData, field_id);
            const item = items.find(e => e[optionValue] === value);
            if (item && item.hasOwnProperty(optionLabel)) {
                if (body) {
                    return body(item[optionLabel],value, id);
                }
                return item[optionLabel];
            }
            return '';
        },
        filterElement: (options: ColumnFilterElementTemplateOptions) => {
        return (<div className="">
                     <Dropdown value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} options={items} optionValue={'value'} optionLabel={'label'} />
                </div>
            )
                },
        showFilterMatchModes: false,
        filterMatchMode: 'equals' as ColumnFilterMatchModeType,
        filterType: 'equals',
    }
};

const TawreedTableDropdownDropdownFilter = (field: string, items: any[], optionValue: string = "value", optionLabel: string = "label", matchMode: 'equals' | 'in' = 'equals', body?: (item: any, optionValue: any) => React.ReactNode) => {
    return {
        body: (rowData: any) => {
            
            const value = ObjectUtils.getNested(rowData, field);
            const item = items.find(e => e[optionValue] === value);
            if (item && item.hasOwnProperty(optionLabel)) {
                if (body) {
                    return body(item[optionLabel],value);
                }
                return item[optionLabel];
            }
            return '';
        },
        filterElement: (options: ColumnFilterElementTemplateOptions) => {
            if (matchMode === 'equals') {
                return <Dropdown value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} options={items} optionValue={optionValue} optionLabel={optionLabel} />;
            }
            return <MultiSelect selectedItemsLabel={tawreedTranslationService.translate('lbl_items_selected', options.value?.length)} value={options.value} options={items} optionValue={optionValue} optionLabel={optionLabel} onChange={(e) => options.filterCallback((!Array.isArray(e.value) || !e.value.length) ? null : e.value, options.index)} className="p-column-filter" maxSelectedLabels={1} />;
        },
        showFilterMatchModes: false,
        filterMatchMode: matchMode,
        filterType: matchMode,
    }
};

export const TawreedTableFilters = {
    date: {
        range: TawreedTableDateRangeFilter,
    },
    boolean: TawreedTableBooleanFilter,
    dropdown: {
        boolean: TawreedTableDropdownBooleanFilter,
        dropdown: TawreedTableDropdownDropdownFilter,
    },
}
