import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { GlobalContext, LayoutColorMode } from "../../../context";
import { TawreedUserDropdown } from "../../../modules";
import { NotificationsTopbarButton } from "../../../modules/notifications/presentaion/components";
import { AuthUtils } from "../../../modules/auth/domain";

export interface AppTopbarProps {
    layoutColorMode: LayoutColorMode;

    onToggleMenuClick: any;
    onMobileTopbarMenuClick: any
    mobileTopbarMenuActive: any;
    onMobileSubTopbarMenuClick: any;
}

export const AppTopbar: React.FC<AppTopbarProps> = ({ layoutColorMode, onToggleMenuClick, onMobileTopbarMenuClick, mobileTopbarMenuActive, onMobileSubTopbarMenuClick }) => {

    const { application } = React.useContext(GlobalContext);

    return (
        <div className="layout-topbar">
            <div  className="layout-topbar-logo">
            <Link to="/">
                <img className="layout-topbar-logo1" src="/assets/images/logo/icon.svg"></img>
            </Link>
            <img src={application.logo[AuthUtils.current().language]["light"]} alt={application.title} />
            </div>

            <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={onToggleMenuClick}>
                <i className="pi pi-bars" />
            </button>

            <ul className={classNames("layout-topbar-menu lg:flex align-items-center", { "layout-topbar-menu-mobile-active": true })}>
                <li>
                    <NotificationsTopbarButton className="p-link layout-topbar-button" onClick={onMobileSubTopbarMenuClick} />
                </li>

                <li>
                    <TawreedUserDropdown className="p-link layout-topbar-button" onClick={onMobileSubTopbarMenuClick} />
                </li>
            </ul>
        </div>
    );
};
