import React, { useContext, useState } from "react";
import { CustomerStoreCartDto, CustomerStoreCartItemDto } from "../../domain";
import { Button } from "primereact/button";
import { PrimeIcons } from "primereact/api";
import { Message } from 'primereact/message';
import { TawreedTable, TawreedTableProps, TawreedTableColumnProps } from "../../../../../common/components/table";
import { TawreedAction, TawreedActionName } from "../../../../../common/actions";
import { TawreedTableEditor } from "../../../../../common/components/table/editors";
import { TawreedTableFormatters } from "../../../../../common/components/table/formatters";
import { CssUtils } from "../../../../../helpers/css";

import './cart-form-store-cart';
import { tawreedTranslationService } from "../../../../../common/translations";
import { Tag } from "primereact/tag";
import { ObjectUtils } from "../../../../../helpers/object";
import { GlobalContext } from "../../../../../context";
import { IBonusType } from "../../../../constants";
import { CartSingleFormSummary } from "./cart-form-single-summary";
import { CartItemUpdateDialog } from "./cart-item-update-dialog";
import { Panel } from "primereact/panel";

export interface CartFormStoreCartProps {
    className: string;
    cart: CustomerStoreCartDto;
    onDelete: (cart: CustomerStoreCartDto) => Promise<void>;
    onDeleteItem: (item: CustomerStoreCartItemDto) => Promise<void>;
    onSave: (item: CustomerStoreCartItemDto) => Promise<boolean>;
    disabled: boolean;
    onCheckout: (cartId?: number) => void;
    priceListId: number;
    priceListName: string;
    salesOfferId?: string | null,
    onAdd: ()=>void,
}

export const CartFormStoreCart: React.FC<CartFormStoreCartProps> = (props) => {
    const { constants: { constants } } = useContext(GlobalContext);
    
    const [updateItemDialog,setUpdateItemDialog] = useState<boolean>(false);

    const [selectedItem, setSelectedItem] = useState<CustomerStoreCartItemDto>();

    let columns: TawreedTableColumnProps[] = [
        {
            dataType: 'text',
            field: "productName",
            header: "lbl_sales_cart_store_cart_items_product_name",
            body: (rowData: CustomerStoreCartItemDto) => {

                const productName = rowData.productName;
                const availableQuantity = rowData.availableQuantity ?? 0;

                return (
                    <div className="flex flex-column align-items-end md:align-items-start">
                        <span>{productName}</span>
                        {
                            availableQuantity < 0 && <Tag value={tawreedTranslationService.translate('lbl_sales_cart_store_cart_items_not_available')} severity="danger" rounded />
                        }
                    </div>
                )
            },
            style: CssUtils.width('30%'),
        },
        {
            dataType: 'text',
            field: "quantity",
            header: "lbl_sales_cart_store_cart_items_quantity",
           
            style: CssUtils.width('10%'),
        },
        {
            dataType: 'numeric',
            field: "retailPrice",
            header: "lbl_sales_cart_store_cart_items_retail_price",
            style: CssUtils.width('10%'),
            className: CssUtils.className.field.amount,
            body: (options: any) => ObjectUtils.toLocalString(options.retailPrice)
        },
        {
            dataType: 'numeric',
            field: "discountValue",
            header: "lbl_base_discount",
            sortable: false,
            filter: false,
            style: CssUtils.width('10%'),
            className: CssUtils.className.field.amount,
            ...TawreedTableFormatters.currencyOrPercentageDepend('discountValue', 'discountType', constants?.info?.currencyCode ?? ''),
        },
        {
            dataType: 'numeric',
            field: "bonus",
            header: "lbl_additional_discount",
            sortable: false,
            filter: false,
            style: CssUtils.width('10%'),
            className: CssUtils.className.field.amount,
            body: (options: any) => (options.bonusType === IBonusType.DISCOUNT ? options.bonus : 0) + " " + constants?.info?.currencyCode,
        },
        {
            dataType: 'numeric',
            field: "bonus",
            header: "lbl_store_products_bonus",
            style: CssUtils.width('10%'),
            body: (options: any) => options.bonusType === IBonusType.EXTRA_ITEMS ? options.bonus : 0
        },

        {
            dataType: 'numeric',
            field: "itemGrossTotal",
            header: "lbl_sub_total1",
            style: CssUtils.width('10%'),
            className: CssUtils.className.field.amount,
            body: (options: any) => ObjectUtils.toLocalString(options.itemGrossTotal - options.itemTotalDiscount)
        },
        {
            dataType: 'numeric',
            field: "storeProductTax",
            header: "lbl_sales_cart_store_cart_items_item_total_tax1",
            style: CssUtils.width('10%'),
            className: CssUtils.className.field.amount,
            body: (options: any) => ObjectUtils.toLocalString(options.storeProductTax)
        },
        {
            dataType: 'numeric',
            field: "itemTotal",
            header: "lbl_total1",
            style: CssUtils.width('10%'),
            className: CssUtils.className.field.amount,
            body: (options: any) => ObjectUtils.toLocalString(options.itemGrossTotal - options.itemTotalDiscount + options.itemTotalTax)
        }];
    if (!props.salesOfferId) {
        columns = [...columns, {
            field: "delete",
            className: CssUtils.className.field.amount,
            body: (options: any) => {
                return (
                    <Button type="button" icon={PrimeIcons.PENCIL} onClick={() => 
                        {
                            setSelectedItem(options);
                            setUpdateItemDialog(true);
                        }
                    } disabled={props.disabled} className="p-button-rounded p-button-text p-button-danger" />
                )
            }
        }];
    }
    const tawreed: TawreedTableProps = {
        reload: true,
        // responsiveLayout: 'scroll',
        dataKey: 'cartItemId',
        name: 'tbl.sales.carts.cart.store',
        ds: {
            mode: 'basic',
            data: props.cart.items ?? [],
        },
        columns: [
            ...columns

        ],

        editable: {
            onEditComplete: (rowData, _field, _value) => props.onSave(rowData),
        },
        header: {
            bottom: props.cart.cartMessage ? <Message severity="warn" text={props.cart.cartMessage} className="w-full justify-content-start" /> : undefined,
        }
    }
    return (
        <Panel header={<React.Fragment>
            <span className="flex flex-row">
                <span className="mr-1">{props.cart.store}</span>
                <span>({ObjectUtils.toLocalString(props.cart.total) + ' JOD'})({props.priceListName})</span>
            </span>
        </React.Fragment>} className="mt-2 col-12"

                icons={
                    <span className="p-panel-header-buttons">
                        {
                            [new TawreedAction(
                                TawreedActionName.CartsStoreCartDelete,
                                'statefull',
                                'lbl_delete',
                                PrimeIcons.TRASH,
                                () => props.onDelete(props.cart),
                                undefined,
                                'confirmDelete')].map(e => <Button type="button" icon={e.icon} onClick={() => e.execute()} disabled={props.disabled} className="p-button-text p-button-danger" label={tawreedTranslationService.translate(e.title.toString())} />)
                                
                        }
                        {!props.salesOfferId && <Button type="button" className="p-button-text p-button-success" icon={PrimeIcons.PLUS} onClick={() => props.onAdd()} label={tawreedTranslationService.translate("lbl_add")} disabled={props.disabled} />}
                    </span>
                }
            >
            <TawreedTable {...tawreed} />
            <CartSingleFormSummary priceListId={props.priceListId} className="col-12" cart={props.cart} onCheckout={props.onCheckout} disabled={props.disabled || !props.cart.showPlaceOrder} cartId={props.cart.cartId} />
            <CartItemUpdateDialog
                onHide={() => { setUpdateItemDialog(false) }}
                onSave={(data) => {
                   props.onSave(data)
                }}
                item={selectedItem!}
                onDeleteItem={(data) => {
                    props.onDeleteItem(data)
                 }}
                visible={updateItemDialog} />
        </Panel>
    )
}
