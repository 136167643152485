import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import React, { FC, useState, useEffect } from 'react'
import { tawreedTranslationService } from '../../../../../common/translations';
import { TawreedFormField, TawreedInputNumber } from "../../../../../common/components/form";
import { DiscountTypesDropDown } from './discount-types';


export interface BonusDialogProps {
    visible: boolean;

    hide: () => void;
    isDiscount: boolean;
    singlePrice?: number;

    accept: (data: { bonus: number, quantity: number, discountType?: string; }) => void;

}

export const BonusDialog: FC<BonusDialogProps> = ({ visible, hide, accept, isDiscount, singlePrice }) => {


    const [dialogState, setDialogState] = useState<{ quantity: number | undefined, bonus: number | undefined, discountType?: string, loading: boolean }>({
        quantity: undefined,
        bonus: undefined,
        loading: false,
        discountType: undefined,
    });


    useEffect(() => {

        setDialogState({
            quantity: undefined,
            bonus: undefined,
            loading: false,
        })
    }, [visible])


    const getSellingPrice = () => {
        let discountType = 'fixed';
        if (dialogState.discountType === 'percentage')
            discountType = 'percentage';
        if (dialogState.discountType === 'bonus')
            discountType = 'bonus';
        let discountValue = 0;
        if (dialogState.bonus)
            discountValue = dialogState.bonus;
        let sellingPrice = singlePrice || 0;
        sellingPrice -= (discountType === 'fixed' ? discountValue : (singlePrice! * discountValue / 100));
        if (discountType === 'bonus') {
            sellingPrice = singlePrice! - (singlePrice! * (discountValue / (discountValue + 100)));
        }
        if (sellingPrice <= 0 && discountType !== 'bonus')
            return 0;
        return sellingPrice;

    }

    const BonusDialogFooter = () => {
        return (<div>
            <Button label={tawreedTranslationService.translate('lbl_cancel')} icon="pi pi-times" onClick={hide} className="p-button-text" disabled={dialogState.loading} />
            <Button onClick={() => accept({ bonus: dialogState.bonus!, quantity: dialogState.quantity!, discountType: dialogState.discountType ?? 'fixed' })} label={tawreedTranslationService.translate('lbl_accept')} icon="pi pi-check" disabled={!dialogState || dialogState.loading || !dialogState.bonus || !dialogState.quantity || (isDiscount && getSellingPrice() <= 0)}
                loading={dialogState.loading} />
        </div>);
    }

    return (
        <Dialog style={{}} header={tawreedTranslationService.translate(!isDiscount ? 'lbl_bonus' : 'lbl_store_products_discount_bonus_title')} visible={visible} footer={<BonusDialogFooter />} onHide={hide} draggable={false} position="top" closable={false} closeOnEscape={false}>
            <form className="formgrid grid" >
                {!isDiscount &&
                    <React.Fragment>
                        <TawreedFormField name="quantity" title="lbl_store_products_bonus_qty" className="field col-12 md:col-6">
                            <TawreedInputNumber value={dialogState?.quantity} name="quantity" rules={{ required: "msg_field_is_required" }} render="form" onChange={(e) => {
                                setDialogState({ ...dialogState, quantity: e.value ? e.value : 0 });
                            }} />
                        </TawreedFormField>
                        <TawreedFormField name="bonus" title={!isDiscount ? "lbl_store_products_bonus" : "lbl_store_products_discount_bonus_val"} className="field col-12 md:col-6">
                            <TawreedInputNumber mode={isDiscount ? 'decimal' : undefined} value={dialogState?.bonus} name="bonus" rules={{ required: "msg_field_is_required" }} render="form" onChange={(e) => {
                                setDialogState({ ...dialogState, bonus: e.value ? e.value : 0 });
                            }} />
                        </TawreedFormField>
                    </React.Fragment>
                }
                {isDiscount &&
                    <React.Fragment>
                        <TawreedFormField name="quantity" title="lbl_store_products_bonus_qty" className="field col-4 lg:col-4 md:col-6">
                            <TawreedInputNumber value={dialogState?.quantity} name="quantity" rules={{ required: "msg_field_is_required" }} render="form" onChange={(e) => {
                                setDialogState({ ...dialogState, quantity: e.value ? e.value : 0 });
                            }} />
                        </TawreedFormField>
                        <TawreedFormField className="field col-4 lg:col-4 md:col-6" name="discountType" title="lbl_price_scheme_discountType">
                            <DiscountTypesDropDown value={dialogState?.discountType} name="discountType" render="form" rules={{ required: 'msg_field_is_required' }} onChange={(e) => {
                                setDialogState({ ...dialogState, discountType: e.value })
                            }} />
                        </TawreedFormField>
                        <TawreedFormField name="bonus" title={!isDiscount ? "lbl_store_products_bonus" : "lbl_store_products_discount_bonus_val"} className="field col-4 lg:col-4 md:col-6">
                            <TawreedInputNumber mode={isDiscount ? 'decimal' : undefined} value={dialogState?.bonus} name="bonus" rules={{ required: "msg_field_is_required" }} render="form" onChange={(e) => {
                                setDialogState({ ...dialogState, bonus: e.value ? e.value : 0 });
                            }} />
                        </TawreedFormField>

                        {(getSellingPrice() >= 0) ?
                            <React.Fragment>
                                <TawreedFormField name="sellingPrice" title="lbl_sellingPrice_befor_per_unit" className="field col-6 lg:col-6 md:col-6 mt-2">
                                    <TawreedInputNumber mode="decimal" disabled suffix=" JOD" value={singlePrice} name="sellingPrice" render="standalone" readOnly />
                                </TawreedFormField>
                                <TawreedFormField name="sellingPrice" title="lbl_sellingPrice_befor_after_unit" className="field col-6 lg:col-6 md:col-6 mt-2">
                                    <TawreedInputNumber mode="decimal" disabled suffix=" JOD" value={getSellingPrice()} name="sellingPrice" render="standalone" readOnly />
                                </TawreedFormField>
                            </React.Fragment>
                            : ''
                        }
                    </React.Fragment>
                }
            </form>
        </Dialog>
    )
}
