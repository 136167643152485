import React, { useEffect, useState } from "react";
import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import { tawreedTranslationService } from "../../../../../common/translations";
import { Dialog } from "primereact/dialog";
import { TawreedFormField, TawreedInputNumber } from "../../../../../common/components/form";

import { CustomerStoreCartItemDto } from "../../domain";


export interface CartItemProps {
    item:CustomerStoreCartItemDto,
    onDeleteItem: (item: CustomerStoreCartItemDto) => void;
    onSave: (item: CustomerStoreCartItemDto) => void;
    visible: boolean;
    onHide: ()=>void;
}

export const CartItemUpdateDialog: React.FC<CartItemProps> = (props) => {

    const [quantity,setQuantity] = useState<number>(props.item ? props.item.quantity! :  0);


    const DialogFooter = () => {
        return (
            <div>
                <Button className="p-button-text p-button-text" label={tawreedTranslationService.translate('lbl_close')} icon={PrimeIcons.TIMES} onClick={() => onHide(false)} />
                <Button className="p-button-text p-button-text" label={tawreedTranslationService.translate('lbl_okay')}
                    icon={PrimeIcons.CHECK}

                    disabled={!quantity || quantity> props.item.availableQuantity!}
                    onClick={() => { props.onSave({...props.item, quantity:quantity}); props.onHide()  }}
                />
                <Button className="p-button-text p-button-danger p-button-text" label={tawreedTranslationService.translate('lbl_delete')}
                    icon={PrimeIcons.TRASH}
                    onClick={() => {props.onDeleteItem(props.item); props.onHide() }}
                />
            </div>
        );
    }

    useEffect(()=>{
        if(props.item)
            setQuantity(props.item.quantity!)
        else{
            setQuantity(0);
        }
    },[props.item]);

    const onHide = (res: boolean) => {
        if (props.onHide) {
            props.onHide();
        }
    }

    return (
        <Dialog visible={props.visible} onHide={() => onHide(false)}
            header={tawreedTranslationService.translate('lbl_checkout_form')} footer={<DialogFooter />}
            position="top" breakpoints={{ '960px': '75vw', '640px': '100vw' }} style={{ width: '40vw' }} draggable={false} closable={false} focusOnShow closeOnEscape={false}>

            <div className="form-grid grid">
               
            <TawreedFormField name="quantity" title="lbl_sales_cart_store_cart_items_quantity" className="field col-12">
                        <TawreedInputNumber
                            name="quantity"
                            render="standalone"
                            value={quantity}
                            onChange={(e) => {
                                console.log("e",e.value); 
                                setQuantity(e.value ?? 0);
                            }} />

                    </TawreedFormField>

            </div>
        </Dialog>
    );
}
