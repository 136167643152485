import { MenuItem } from "primereact/menuitem";
import { Routes } from "../router";

export function getMenu(): Array<MenuItem> {
    return [
        {
            label: 'lbl_home',
            items: [
                {
                    label: "lbl_dashboard",
                    icon: '/assets/images/sidebar/dashboard.svg',
                    to: Routes.Dashboard,
                },
            ],
        },
        {
            label: 'lbl_catalog',
            items: [
                { label: "lbl_store_products", icon: '/assets/images/sidebar/storesproducts.svg', to: Routes.StoreProductsSearch },
                { label: "lbl_sales_offer_list_title", icon: '/assets/images/sidebar/products.svg', to: Routes.SalesOfferSearch },
                { label: "lbl_import_helper", icon: '/assets/images/sidebar/storesproducts.svg', to: Routes.ImportHelper },
                { label: "lbl_import_store_product", icon: '/assets/images/sidebar/storesproducts.svg', to: Routes.ImportStoreProductsSearch },
            ]
        },
        {
            label: 'lbl_sales',
            items: [
                { label: "lbl_sales_carts", icon: '/assets/images/sidebar/shoppingcarts.svg', to: Routes.SalesCartsSearch },
                { label: "lbl_sales_stores_placed_orders", icon: '/assets/images/sidebar/ordersplaced.svg', to: Routes.SalesOrdersPlacedSearch },
                { label: "lbl_sales_stores_processing_orders", icon: '/assets/images/sidebar/ordersprocessing.svg', to: Routes.SalesOrdersProcessingSearch },
                { label: "lbl_sales_stores_ready_orders", icon: '/assets/images/sidebar/ordersready.svg', to: Routes.SalesOrdersReadySearch },
                { label: "lbl_sales_customers_shipped_orders", icon: '/assets/images/sidebar/ordersshipped.svg', to: Routes.SalesOrdersShippedSearch },
                { label: "lbl_sales_orders", icon: '/assets/images/sidebar/orders.svg', to: Routes.SalesOrdersSearch },
            ]
        },
        
        {
            label: 'lbl_reports',
            items: [
                {
                    label: 'lbl_reports_financial',
                    icon: '/assets/images/sidebar/reportsfinance.svg',
                    items: [
                        { label: 'lbl_reports_financial_documents', icon: '/assets/images/sidebar/reportsfinance.svg', to: Routes.ReportsFinancialDocuments },
                        { label: 'lbl_reports_financial_statements_per_partner', icon: '/assets/images/sidebar/reportsfinance.svg', to: Routes.ReportsFinancialStatementsPerPartner },
                        { label: 'lbl_reports_financial_statements_per_account', icon: '/assets/images/sidebar/reportsfinance.svg', to: Routes.ReportsFinancialStatementsPerAccount },
                        { label: 'lbl_reports_invoices', icon: '/assets/images/sidebar/reportsfinance.svg', to: Routes.ReportsInvoices },
                        { label: 'lbl_reports_invoices_returned', icon: '/assets/images/sidebar/reportsfinance.svg', to: Routes.ReportsReturnedInvoices },
                        { label: 'lbl_reports_trial_balance', icon: '/assets/images/sidebar/reportsfinance.svg', to: Routes.ReportsTrialBalance },
                    ]
                },
                {
                    label: 'lbl_reports_sales',
                    icon: '/assets/images/sidebar/reportssales.svg',
                    items: [
                        { label: 'lbl_reports_sales_orders', icon: '/assets/images/sidebar/reportssales.svg', to: Routes.ReportsSalesOrders },
                        { label: 'lbl_reports_sales_returned_orders', icon: '/assets/images/sidebar/reportssales.svg', to: Routes.ReportsSalesReturnedOrders },
                    ]
                }
            ]
        }
    ];
}
