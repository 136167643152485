import { buildFilterMetaToObject, buildSortParamsToString, PaginatedRequestParams, PaginatedResponse, Result } from "../../../../../common/pagination";
import { tawreedHttpService } from "../../../../../http";
import { endpoints } from "../../../../../constants/endpoints";
import { ProductDto, ProductMatchDto } from "../../domain";
import { ProductMapper, ProductMatchMapper } from "../mappers";
import { JObject } from "../../../../../common/data/models";
import { ProductsFileRepository } from "./products-file.repository";
import axios from "axios";


export class ProductsRepository {

    private filesService = new ProductsFileRepository();

    public search(request: PaginatedRequestParams): Promise<PaginatedResponse<ProductDto>>;
    public search(api: 'similar', q: string): Promise<PaginatedResponse<ProductDto>>;

    public search(arg: 'similar' | PaginatedRequestParams, q?: string): Promise<PaginatedResponse<ProductDto>> {
        if (arg === 'similar') {
            const params = { sort: 'display_name,asc', page: 0, size: 25 };
            const data = {
                productName: q?.split('§')[0] ?? '',
                storeId: +(q?.split('§')[1] ?? -1)
            }
            return tawreedHttpService.post<JObject, Result<JObject>>(endpoints.SAERCH_PRODUCTS_SIMILAR, data, params, undefined, 'error')
                .then(res => {
                    return {
                        ...res!,
                        data: { ...res!.data!, content: res!.data!.content.map(e => ProductMapper.toDto(ProductMapper.fromJson(e))) ?? [] },
                    };
                });
        } else {
            const params = {
                sort: arg.sort ? buildSortParamsToString(arg.sort) : undefined,
                page: arg.page,
                size: arg.size,
            };
            const data = { ...buildFilterMetaToObject(arg.keyword, arg.filters) };
            return tawreedHttpService.post<JObject, Result<JObject>>(endpoints.SAERCH_PRODUCTS, data, params, undefined, 'error')
                .then(res => {
                    return {
                        ...res!,
                        data: { ...res!.data!, content: res!.data!.content.map(e => ProductMapper.toDto(ProductMapper.fromJson(e))) ?? [] },
                    };
                });
        }
    }

    /**
     * details
     **/
    public getDetails(id: number): Promise<ProductDto> {
        return tawreedHttpService.post<number, JObject>(endpoints.GET_PRODUCT, id, undefined, undefined, 'error').then(res => {
            return this.getProductImage(res.data!.image, res.data!.categoryId).then((image) => {
                return ProductMapper.toDto(ProductMapper.fromJson({ ...res.data!, image: image }))
            })


        });
    }
    public async getProductImage(image: string, categoryId: number) {
        const categoryImage = image.split("/products")[0] + "/categories/" + categoryId.toString();
        return axios.get(image).then((res) => image).catch((er) => {
            if (er.response?.status === 403)
                return categoryImage;
            return image;
        });

    }

    public getMatches(id: number): Promise<ProductMatchDto[]> {
        return tawreedHttpService.post<number, JObject>(endpoints.MATCH_PRODUCT, id, undefined, undefined, 'error').then(res => {
            if (res && res.data) {
                return res.data.map((el: JObject) => ProductMatchMapper.toDto(ProductMatchMapper.fromJson(el)));
            }
            return [];
        });
    }
}
